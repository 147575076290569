<div class="nv-stage-container">
    <div class="container">

        <section href="#section-desktop" class="d-none d-lg-block">
            <div class="row mt-5 mb-3">
                <div class="col-12 mt-5">
                    <h2>Ups, die Luft ist raus.</h2>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-3">
                    <img src="assets/FlatTire_blau.svg" class="tire"/>
                </div>
                <div class="col-9">
                    <p>Die Seite kann leider nicht angzeigt werden. Bitte versuchen Sie es später noch einmal.</p>
                    <p><strong>Sollten die Probleme weiterhin auftreten, helfen wir Ihnen gerne über unser kostenfreies
                        Service-Center weiter.</strong></p>
                    <p><em class="fas fa-phone"></em><a class="link-col" href="tel:08005318110"><strong>0800 531
                        8110</strong></a></p>
                </div>
            </div>
        </section>

        <section href="#section-mobile" class="d-block d-lg-none">
            <div class="row">
                <div class="col-12">
                    <img src="assets/FlatTire_blau.svg" class="tire"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>Ups, die Luft ist raus.</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>Die Seite kann leider nicht angzeigt werden. Bitte versuchen Sie es später noch einmal.</p>
                    <p><strong>Sollten die Probleme weiterhin auftreten, helfen wir Ihnen gerne über unser kostenfreies
                        Service-Center weiter.</strong></p>
                    <p><em class="fas fa-phone"></em><a class="link-col text-decoration-none"
                                                        href="tel:08005318110"><strong>0800 531 8110</strong></a></p>
                </div>
            </div>
        </section>

        <div class="row">
            <div class="col-12">
                <p>Wir helfen bei allen Fragen rund um die Autoversicherung - auch bei speziellen Themen,
                    wie zum Beispiel
                    Sondernachlässe, Zweitwägen oder Eltern-Kind-Nachlässe. Auf Wunsch übernehmen unsere Mitarbeiter die
                    komplette Antragsstellung für Sie.</p>
                <p>Sie erreichen uns Montag bis Freitag von 9 bis 18:30 Uhr. Am Samstag und Sonntag findet keine
                    telefonische
                    Beratung statt.</p>
                <p>Wir freuen uns auf Ihren Anruf.</p>
            </div>
        </div>
        <div class="row mb-5">
            <div class="p-0 mt-2 mb-5 col-12 pr-3 pl-3">
                <button class="btn nv-btn-tertiäre" role="button"><a [routerLink]="['/login']">Zurück</a></button>
            </div>
        </div>

    </div>
</div>
