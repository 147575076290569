import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule, Provider} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoginPageComponent } from './Pages/login-page/login-page.component';
import { ErrorPageComponent } from './Pages/error-page/error-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { APP_BASE_HREF } from '@angular/common';
import { BASE_PATH, ApiModule, Configuration, ConfigurationParameters } from '@coc-kfz-digital/nau-rest-api-client';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginPageComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot(ApiConfigFactory),
    ReactiveFormsModule,
    SharedModule
  ],
  providers: AppModuleProviderFactory(),
  bootstrap: [AppComponent]
})
export class AppModule { }


/**
 * Configuration factory for REST Api.
 * At least empty configuration needs to be created here.
 */
export function ApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set default configuration parameters here.
    withCredentials: true
  };

  return new Configuration(params);
}

export function AppModuleProviderFactory(): Provider[] {
  return [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: LOCALE_ID, useValue: 'de' }
  ];
}
