import {AuthenticationRequest, LoginService} from '@coc-kfz-digital/nau-rest-api-client';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {HttpResponseBase} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    carSaleId: string;

    constructor(
        private loginService: LoginService) {
    }


    /**
     * Do an authentication call to the backend for the current car sale id.
     *
     * @param password the password used for authentication
     */
    public authenticate(password: string): Observable<HttpResponseBase> {
        const authRequest: AuthenticationRequest = { token: password };

        const authObservable = this.loginService.authenticate(this.carSaleId, authRequest, 'response').pipe(
            switchMap(data => {
                return of(data);
            }),
            catchError(err => {
                return of(err);
            })
        );

        return authObservable;
    }

    public login(carSaleId: string): Observable<any> {
        this.carSaleId = carSaleId;
        const loginObservable = this.loginService.login(this.carSaleId, 'response').pipe(switchMap(data => {
            return of(data.ok);
        }));

        return loginObservable;
    }
}
