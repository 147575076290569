<app-header></app-header>

<div class="p-0" id="body" class="fixed">
    <section>
        <router-outlet></router-outlet>
    </section>

</div>

<app-footer></app-footer>
