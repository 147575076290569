export interface PathName {
    [key: string]: Path;
}

export interface Path {
    url: string;
    name: string;
}

export const PathNames: PathName = {
    loginPage: { url: 'login', name: 'Anmelden'},
    errorPage: { url: 'fehler', name: 'Fehler'}
};

export const PathParams = {
    carSaleId: 'carsaleId'
};
