import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { AppInjectorService } from './services/app-injector.service';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class SharedModule {
  constructor(private injector: Injector) {
    AppInjectorService.setInjector(this.injector);
  }
}
