<footer class="nv-footer d-flex align-content-end flex-wrap">
    <div class="footer-bound px-md-0">
        <hr class="nv-break-separator">
        <div class="row">
            <div class="col-lg-6 col-12 px-0">
                <ul class="list-unstyled d-lg-inline-flex">
                    <li class="p-1 first-link"><a href="https://www.nuernberger-automobil.de/de/kfz-digital-datenschutz" target="_blank" rel="noopener noreferrer">Datenschutz </a></li>
                    <li class="p-1"><a href="https://www.nuernberger-automobil.de/de/kfz-digital-nutzungsbedingungen" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a></li>
                    <li class="p-1"><a href="https://www.nuernberger-automobil.de/de/impressum" target="_blank">Impressum</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

