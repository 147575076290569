<div class="nv-header fixed" id="header">
    <div class="nv-header-container">
        <div class="row d-flex align-middle">
            <div class="col-6 pb-sm-3 d-flex mb-4 align-items-center">
                <div class="nv-header-logo">
                    <img src="../../../assets/logos/nau/NAU_Logo_blau_RGB.svg">
                </div>
            </div>
            <div class="col-6 pb-sm-3 d-flex justify-content-end">
                <div class="align-middle button-position">
                    <div class="d-none d-md-block float-right ml-2 button-text-position">
                        <a class="text-decoration-none text-primary" role="button" data-toggle="modal"
                           data-target=".bd-example-modal-lg"><strong>Fragen?</strong><br>
                            <span>Rufen Sie uns an</span></a></div>

                    <button class="btn nv-cta-bn" data-toggle="modal" data-target=".bd-example-modal-lg">
                        <i class="fas fa-phone"></i>
                    </button>
                </div>
            </div>
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                 aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="triangle-modal d-none d-lg-block"></div>
                    <div class="modal-content">
                        <div class="row d-flex justify-content-end">
                            <button type="button" class="close close-btn text-primary" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="p-3">×</span>
                            </button>
                        </div>
                        <div class="px-4">
                            <h3 class="mb-3">Haben Sie Fragen?</h3>
                            <div class="row">

                                <div class="col-lg-6"><p>Unser kostenfreies Service-Center erreichen Sie unter</p>
                                    <p class="mt-4"><a class="link-col text-decoration-none" href="tel:08005318110">0800 531 8110</a>
                                    </p>
                                    <p>Wir helfen bei allen Fragen rund um die Autoversicherung - auch bei speziellen
                                        Themen, wie zum Beispiel Sondernachlässe, Zweitwägen oder
                                        Eltern-Kind-Nachlässe.</p>
                                </div>
                                <div class="col-lg-6"><p>Auf Wunsch übernehmen unsere Mitarbeiter die komplette
                                    Antragsstellung für Sie.</p>
                                    <p>Sie erreichen uns Montag bis Freitag von 9 bis 18:30 Uhr. Am Samstag und Sonntag
                                        findet keine telefonische Beratung statt.</p>
                                    <p>Wir freuen uns auf Ihren Anruf.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


