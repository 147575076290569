<section href="#section-desktop" class="nv-stage-container d-none d-lg-block">
    <div class="row">
        <div class="col-lg-6">
            <div class="picture-text mt-5">
                <h4 class="sub-header-style my-4" *ngIf="carSale">Herzlichen Glückwunsch zu ihrem {{carSale.car.model}}!</h4>
                <h1 class="mb-4" *ngIf="carSale">Hallo {{carSale.customerProfile.gendericedName}},</h1>
                <p>für die Zulassung benötigen Sie jetzt noch den passenden Versicherungsschutz und die
                    Bestätigung für die Zulassungsstelle (eVB).</p>
                <p class="mb-4">Geben Sie jetzt einfach den Zugangscode aus unserer SMS ein und berechnen Sie Ihren
                    persönlichen Versicherungstarif von unserem Produktpartner NÜRNBERGER Versicherung.</p>
                <form [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="p-0 form-group" *ngIf="profileForm.get('codeField') as codeField">
                                <input type="text" class="form-control mt-3" formControlName="codeField"
                                       placeholder="Ihr Zugangscode" maxlength="6" [value]="code">
                                <div class="invalid-feedback d-block" *ngIf="codeField.errors as error">
                                    <p *ngIf="error?.required && codeField.touched">Der Zugangscode ist leider
                                        nicht korrekt. Bitte prüfen Sie den Code und versuchen Sie es
                                        erneut.</p>
                                    <p *ngIf="error?.invalid == 'USER_PASSWORD_MISMATCH'">Der Zugangscode ist leider nicht korrekt.
                                        Bitte prüfen Sie den Code und versuchen Sie es erneut.</p>
                                    <p *ngIf="error?.invalid == 'PASSWORD_TEMP_DISABLED'">Ihr Zugang ist deaktiviert.
                                        Bitte versuchen Sie es in {{errorParam}} Minute{{errorParam > 1 ? 'n' : ''}} erneut. </p>
                                    <p *ngIf="error?.invalid == 'TOKEN_DISABLED'">Der eingegebene Code ist nicht mehr
                                        Gültig.</p>
                                </div>

                                <p class="mb-3 mt-4">Komfortabel für Sie: Ihre persönlichen Angaben sowie die
                                    Fahrzeugdaten sind
                                    dann bereits vorbelegt.</p>
                            </div>
                            <div class="mb-3 form-group" *ngIf="profileForm.get('dataCheck') as dataCheck">
                                <div class="my-3">

                                    <label class="customcheck"
                                           [ngClass]="dataCheck.touched && dataCheck.errors?.required ? 'text-danger' : null"
                                           for="dataCheck-desktop">
                                        Hiermit akzeptiere ich die
                                        <strong>
                                            <a href="https://www.nuernberger-automobil.de/de/kfz-digital-datenschutz"
                                               target='_blank noopener noreferrer'>Datenschutzhinweise</a>
                                        </strong>
                                        und
                                        <strong>
                                            <a href="https://www.nuernberger-automobil.de/de/kfz-digital-nutzungsbedingungen"
                                               target='_blank noopener noreferrer'>Nutzungsbedingungen</a></strong>.
                                        <input type="checkbox" class="form-check-input"
                                               formControlName="dataCheck" [checked]="checked"
                                               id="dataCheck-desktop">

                                        <span class="checkmark"
                                              [ngClass]="dataCheck.touched && dataCheck.errors?.required ? 'checkbox-border' :null "></span>

                                    </label>
                                </div>
                                <div class="invalid-feedback d-block" *ngIf="dataCheck.errors as error">
                                    <p *ngIf="error?.required && dataCheck.touched || dataCheck.dirty">
                                        Bitte bestätigen Sie die Datenschutzhinweise und Nutzungsbedingungen.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 p-0 mt-3">
                        <button [disabled]="!profileForm.valid"
                                class="btn nv-btn-accent text-white btn-block" type="submit">Jetzt
                            starten
                        </button>
                    </div>
                </form>
                <p class="my-5">Profitieren Sie von der Sicherheit der soliden Muttergesellschaft NÜRNBERGER
                    Versicherung. Verlassen Sie sich auf unsere langjährige Erfahrung in der Kfz-Branche.</p>

            </div>
        </div>
        <div class="col-lg-6 position-relative">
            <img class="desktop-pic" src="/assets/desktop-1.jpg"/>
            <div class="logo-banner">
                <div class="col-6 m-auto logo-banner-column">
                    In Kooperation mit <br> Ihrem Autohaus
                </div>
                <div class="col-6 m-auto logo-banner-column">
                    <img class="dealer-logo" [src]="dealerLogo"/>
                </div>
            </div>
        </div>
    </div>
</section>

<section href="#section-mobile " class="d-block d-lg-none">
    <div class="row mr-0 ml-0">
        <div class="position-relative pic-section">
            <img class="mobile-pic position-relative" src="/assets/mobile-3.jpg"/>
            <div class="logo-banner position-absolute">
                <div class="container">
                    <div class="row">
                        <div class="col-6 m-auto">
                            In Kooperation mit Ihrem Autohaus
                        </div>
                        <div class="col-6 m-auto">
                            <img class="dealer-logo" [src]="dealerLogo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container px-md-0">
        <div class="px-0">
            <div class="picture-text">

                <h3 class="mt-4 mb-3" *ngIf="carSale">Hallo {{carSale.customerProfile.gendericedName}}</h3>
                <p>für die Zulassung benötigen Sie jetzt noch den passenden Versicherungsschutz und die
                    Bestätigung für die Zulassungsstelle (eVB).</p>
                <p class="mb-4">Geben Sie jetzt einfach den Zugangscode aus unserer SMS ein und berechnen Sie Ihren
                    persönlichen Versicherungstarif von unserem Produktpartner NÜRNBERGER Versicherung.</p>
                <form [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
                    <div class="form-row">
                        <div class="col-12">
                            <div class="p-0 form-group" *ngIf="profileForm.get('codeField') as codeField">
                                <input type="text" class="form-control mt-3" formControlName="codeField"
                                       id="codeField" [value]="code"
                                       placeholder="Ihr Zugangscode" maxlength="6">
                                <div class="invalid-feedback d-block" *ngIf="codeField.errors as error">
                                    <p *ngIf="error?.required && codeField.touched">Der Zugangscode ist leider
                                        nicht korrekt. Bitte prüfen Sie den Code und versuchen Sie es
                                        erneut.</p>
                                    <p *ngIf="error?.invalid == 'USER_PASSWORD_MISMATCH'">Der Zugangscode ist leider nicht korrekt.
                                        Bitte prüfen Sie den Code und versuchen Sie es erneut.</p>
                                    <p *ngIf="error?.invalid == 'PASSWORD_TEMP_DISABLED'">Ihr Zugang ist deaktiviert.
                                        Bitte versuchen Sie es in {{errorParam}} Minute{{errorParam > 1 ? 'n' : ''}} erneut. </p>
                                    <p *ngIf="error?.invalid == 'TOKEN_DISABLED'">Der eingegebene Code ist nicht mehr
                                        Gültig.</p>
                                </div>

                                <p class="mb-3 mt-4">Komfortabel für Sie: Ihre persönlichen Angaben sowie die
                                    Fahrzeugdaten sind
                                    dann bereits vorbelegt.</p>
                            </div>
                            <div class="mb-3 form-group" *ngIf="profileForm.get('dataCheck') as dataCheck">
                                <div class="mb-2">

                                    <label class="customcheck"
                                           [ngClass]="dataCheck.touched && dataCheck.errors?.required ? 'text-danger' : null"
                                           for="dataCheck">
                                        Hiermit akzeptiere ich die
                                        <strong>
                                            <a href="https://www.nuernberger-automobil.de/de/kfz-digital-datenschutz"
                                               target='_blank noopener noreferrer'>Datenschutzhinweise</a>
                                        </strong>
                                        und
                                        <strong>
                                            <a href="https://www.nuernberger-automobil.de/de/kfz-digital-nutzungsbedingungen"
                                               target='_blank noopener noreferrer'>Nutzungsbedingungen</a></strong>.
                                        <input type="checkbox" class="form-check-input"
                                               formControlName="dataCheck" [checked]="checked"
                                               id="dataCheck">

                                        <span class="checkmark"
                                              [ngClass]="dataCheck.touched && dataCheck.errors?.required ? 'checkbox-border' :null "></span>

                                    </label>
                                </div>
                                <div class="invalid-feedback d-block" *ngIf="dataCheck.errors as error">
                                    <p *ngIf="error?.required && dataCheck.touched || dataCheck.dirty">
                                        Bitte bestätigen Sie die Datenschutzhinweise und Nutzungsbedingungen.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 col-12 p-0 mt-3">
                        <button [disabled]="!profileForm.valid"
                                class="btn nv-btn-accent text-white btn-block" type="submit">Jetzt
                            starten
                        </button>
                    </div>
                </form>
                <p class="my-5">Profitieren Sie von der Sicherheit der soliden Muttergesellschaft NÜRNBERGER
                    Versicherung. Verlassen Sie sich auf unsere langjährige Erfahrung in der Kfz-Branche.</p>
            </div>
        </div>
    </div>
</section>

<div class="container px-md-0">
</div>
