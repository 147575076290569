import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../common/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppInjectorService} from '../../shared/services/app-injector.service';
import {
    CarSale,
    CarSaleService,
    CarSaleLogoService,
    Consent,
    ConsentService,
    InlineResponse200
} from '@coc-kfz-digital/nau-rest-api-client';
import {Observable, of, timer} from 'rxjs';
import {switchMap, takeWhile} from 'rxjs/operators';
import {PathNames} from '../../app-routing-pathnames';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    protected injector: Injector;

    profileForm: FormGroup;
    authService: AuthService;
    carSaleId: string;
    carSale: CarSale;
    salutation: string;
    dealerLogo: string;
    errorParam: number;
    checked: boolean;
    code: number;

    constructor(private fBuilder: FormBuilder,
                private route: ActivatedRoute,
                private carSaleService: CarSaleService,
                private carSaleLogoService: CarSaleLogoService,
                private consentService: ConsentService,
                private router: Router) {

        this.injector = AppInjectorService.getInjector();
        this.authService = this.injector.get(AuthService);
        this.profileForm = this.fBuilder.group({
            codeField: ['', [Validators.required]],
            dataCheck: ['', [Validators.requiredTrue]]
        });
    }

    ngOnInit(): void {
        this.carSaleId = this.route.snapshot.paramMap.get('carsaleId');
        this.carSaleService.getCarSale(this.carSaleId).subscribe(data => {
                this.carSale = data;
            },
            error => this.router.navigate([PathNames.errorPage.url]));
        this.carSaleLogoService.getSellerLogoUrl(this.carSaleId).subscribe(data => {
            this.dealerLogo = data.url;
        });
        this.authService.login(this.carSaleId).subscribe();
        this.handleFormChanges();
    }

    onSubmit = (event: Event): void => {
        event.preventDefault();

        if (this.profileForm.invalid) {
            const codeFieldSec = this.profileForm.get('codeField');
            const dataCheckSec = this.profileForm.get('dataCheck');

            if (!codeFieldSec.value) {
                codeFieldSec.markAllAsTouched();
                codeFieldSec.setErrors({required: true});
            }

            if (!dataCheckSec.value) {
                dataCheckSec.markAllAsTouched();
                dataCheckSec.setErrors({required: true});
            }

            return;
        }

        const {
            codeField
        } = this.profileForm.value;

        this.authService.authenticate(codeField).subscribe(data => {
            if (data instanceof HttpResponse) {
                this.setConsentObservable()
                    .subscribe({
                        next: (response) => {
                            if (response.success === true) {
                                // redirect to EADO-Page
                                window.open(data.body.eadoUrl, '_self');
                            }
                        }
                    });
            } else if (data instanceof HttpErrorResponse) {
                const errorCode = data.error.error.code;
                this.errorParam = data.error.error.parameter;
                this.profileForm.get('codeField').setErrors({invalid: errorCode});
                if (errorCode === 'PASSWORD_TEMP_DISABLED') {
                    this.minuteCountdown();
                }
                console.log(data);
            }
        });
    }

    /**
     * counts down the minute timer when the login code has been disabled due to
     * too many incorrect inputs
     */
    private minuteCountdown(): void {
        const minuteCountdown = timer(60000, 60000)
            .pipe(takeWhile(x => this.errorParam > 0))
            .subscribe(x => {
                this.errorParam = (this.errorParam) - 1;
                if (this.errorParam === 0) {
                    this.profileForm.get('codeField').setErrors(null);
                    minuteCountdown.unsubscribe();
                }
            });
    }

    /**
     * creating an observable for each consent object and joining them via forkJoin.
     * the resulting observable response gets transformed via the switchMap operator
     * in order to fit as a parameter into the handleConsentResponse method.
     */
    private setConsentObservable(): Observable<InlineResponse200> {

        const dataProtectionConsent: Consent = {
            name: Consent.NameEnum.DataProtectionGeneralTerms,
            version: '0.1.0',
            accept: true,
        };

        const consentObservables = this.consentService.createCustomerConsent(this.carSaleId, dataProtectionConsent);

        return consentObservables.pipe(switchMap(response => {
            const success = response.success;

            const combinedConsent: InlineResponse200 = {success};
            return of(combinedConsent);
        }));
    }

    private handleFormChanges(): void {
        this.profileForm.get('codeField').valueChanges.subscribe(code => {
            this.code = code;
        });
        this.profileForm.get('dataCheck').valueChanges.subscribe(checked => {
            this.checked = checked;
        });
    }
}



