import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor() { }

  @HostListener('window:scroll')
  onScroll(): void {
    if (window.scrollY < 20) {
      document.getElementById('header').classList.add('fixed');
      document.getElementById('body').classList.add('fixed');
    } else {
      document.getElementById('header').classList.remove('fixed');
      document.getElementById('body').classList.add('fixed');
    }
  }

}
