import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './Pages/login-page/login-page.component';
import {ErrorPageComponent} from './Pages/error-page/error-page.component';
import {PathNames, PathParams} from './app-routing-pathnames';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '', redirectTo: PathNames.loginPage.url, pathMatch: 'full'
      },
      {
        path: PathNames.loginPage.url + '/:' + PathParams.carSaleId, component: LoginPageComponent
      },
      {
        path: PathNames.errorPage.url, component: ErrorPageComponent
      }
    ]
  },
  {
    path: '**', component: ErrorPageComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
